import React from "react";
import {Link} from "react-router-dom";

import styles from "../../pages/home/home.module.scss";

export const ColourMatcher = () => {
    return (
        <div className={`${styles.sliderMain} ${styles.feature}`}>
            <div>
                <div>
                    <Link to="/colour-matcher">
                        <div className={styles.titleWrapper}>
                            <div className={styles.sweetTitle}>
                                <span data-text="Colour">Colour</span>
                                <span data-text="Matcher">Matcher</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div>
                    <p>This colour matcher is a simple UI that will generate a random colour and asks that you match it
                        to
                        one of the pre-defined palette colours. The result is stored within Indexeddb and can be
                        exported as a JSON file. The resulting export is used to train a machine learning model designed
                        for grouping similar colours.</p>
                </div>
            </div>
        </div>
    )
}

export default ColourMatcher;
