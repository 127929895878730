const paletteData = [
    {
        index: 0,
        hex: "#ffffff",
        l: 100,
        a: 0.00526049995830391,
        b: -0.010408184525267927
    },
    {
        index: 1,
        hex: "#d4d4d4",
        l: 85.09199993963415,
        a: 0.004584435012611188,
        b: -0.009070553356904654
    },
    {
        index: 2,
        hex: "#aaaaaa",
        l: 69.61016583003699,
        a: 0.0038823471877336146,
        b: -0.007681434510353746
    },
    {
        index: 3,
        hex: "#7f7f7f",
        l: 53.38896474111432,
        a: 0.0031467297079701417,
        b: -0.00622597542279113
    },
    {
        index: 4,
        hex: "#555555",
        l: 36.14585083971984,
        a: 0.0023647693635220346,
        b: -0.00467882446352208
    },
    {
        index: 5,
        hex: "#2a2a2a",
        l: 17.2978608428131,
        a: 0.0015100292722058928,
        b: -0.0029876748271484566
    },
    {
        index: 6,
        hex: "#000000",
        l: 0,
        a: 0,
        b: 0
    },
    {
        index: 7,
        hex: "#c2d2ec",
        l: 83.76682196029184,
        a: -0.07721933872995024,
        b: -14.602404889444731
    },
    {
        index: 8,
        hex: "#6277ba",
        l: 51.077427979601794,
        a: 10.710394576833703,
        b: -37.724559165115544
    },
    {
        index: 9,
        hex: "#1f499f",
        l: 33.04832696686989,
        a: 18.20405268317124,
        b: -50.82012941045979
    },
    {
        index: 10,
        hex: "#223d93",
        l: 28.861235304454063,
        a: 21.99227731213918,
        b: -50.31866936380577
    },
    {
        index: 11,
        hex: "#223378",
        l: 23.881250493403805,
        a: 18.319534384954206,
        b: -41.5670274978231
    },
    {
        index: 12,
        hex: "#1d265f",
        l: 17.79906551642926,
        a: 16.85721304291274,
        b: -35.24453566267481
    },
    {
        index: 13,
        hex: "#131b4d",
        l: 12.339394129729506,
        a: 15.88710422867566,
        b: -32.00474845275169
    },
    {
        index: 14,
        hex: "#b5d8f2",
        l: 84.66186673042971,
        a: -5.831452307851648,
        b: -16.526858954408063
    },
    {
        index: 15,
        hex: "#75adde",
        l: 68.70993778497713,
        a: -5.168276991843024,
        b: -30.38538922754448
    },
    {
        index: 16,
        hex: "#1482c6",
        l: 52.0527042358498,
        a: -3.7516032164935376,
        b: -43.24172006038967
    },
    {
        index: 17,
        hex: "#0061ab",
        l: 40.393244573039844,
        a: 5.120607608860627,
        b: -46.16861128037755
    },
    {
        index: 18,
        hex: "#004d92",
        l: 32.66355068306093,
        a: 7.915473467023454,
        b: -43.59135393422261
    },
    {
        index: 19,
        hex: "#153671",
        l: 23.63841232124299,
        a: 11.47615181777245,
        b: -37.526607851605306
    },
    {
        index: 20,
        hex: "#121b4d",
        l: 12.275444513282828,
        a: 15.721816841176754,
        b: -32.111657704934956
    },
    {
        index: 21,
        hex: "#b3e4fa",
        l: 87.95541833026351,
        a: -10.778318798485898,
        b: -15.807639335252134
    },
    {
        index: 22,
        hex: "#6acef6",
        l: 78.38038256726279,
        a: -18.12789757573968,
        b: -28.540876606386778
    },
    {
        index: 23,
        hex: "#00baf2",
        l: 70.54469973832732,
        a: -19.54819783405831,
        b: -38.65573674084168
    },
    {
        index: 24,
        hex: "#009de1",
        l: 61.2641542508096,
        a: -9.933391205447395,
        b: -43.874582877156975
    },
    {
        index: 25,
        hex: "#006db5",
        l: 44.622599544254896,
        a: 1.5485046025439098,
        b: -45.26930271568632
    },
    {
        index: 26,
        hex: "#09468b",
        l: 30.16310683083494,
        a: 10.234576286104524,
        b: -43.32502321240962
    },
    {
        index: 27,
        hex: "#121c4c",
        l: 12.486171638570326,
        a: 14.541446762135756,
        b: -31.080675107332524
    },
    {
        index: 28,
        hex: "#bae2dc",
        l: 86.99643402452416,
        a: -14.165747867492861,
        b: -1.4044042184151007
    },
    {
        index: 29,
        hex: "#84cebf",
        l: 77.76253014923617,
        a: -26.385309688374882,
        b: 0.16676212765629472
    },
    {
        index: 30,
        hex: "#00b7a6",
        l: 66.99106694694039,
        a: -42.49847084116265,
        b: -2.3724755774262807
    },
    {
        index: 31,
        hex: "#009e84",
        l: 58.1504591633055,
        a: -41.30199067694684,
        b: 3.7539505617120827
    },
    {
        index: 32,
        hex: "#006d7a",
        l: 41.755243511033434,
        a: -21.204649605803176,
        b: -14.71697610544046
    },
    {
        index: 33,
        hex: "#014f5c",
        l: 30.33011158963849,
        a: -15.65343152018553,
        b: -13.56443483720594
    },
    {
        index: 34,
        hex: "#0e202e",
        l: 11.43983949662373,
        a: -2.148604633056675,
        b: -11.564512703888974
    },
    {
        index: 35,
        hex: "#b8dfc7",
        l: 85.54898147428838,
        a: -17.39101933433157,
        b: 7.544741023738699
    },
    {
        index: 36,
        hex: "#87cba0",
        l: 76.31463637418678,
        a: -30.560860585260198,
        b: 14.740326771554258
    },
    {
        index: 38,
        hex: "#35b776",
        l: 66.46782965900769,
        a: -50.00442665999172,
        b: 23.190718814222542
    },
    {
        index: 38,
        hex: "#009d4d",
        l: 56.73256594882024,
        a: -53.29407002118036,
        b: 32.04726083706977
    },
    {
        index: 39,
        hex: "#00753c",
        l: 42.83671893292857,
        a: -42.27247901370418,
        b: 23.617928573179682
    },
    {
        index: 40,
        hex: "#00542e",
        l: 30.73090343058876,
        a: -32.52957476488822,
        b: 16.061870219616303
    },
    {
        index: 41,
        hex: "#051f09",
        l: 9.25490633767491,
        a: -15.236388814241664,
        b: 9.864762301252789
    },
    {
        index: 42,
        hex: "#d5e9bf",
        l: 89.94542059353786,
        a: -13.93476100140889,
        b: 18.225702031493984
    },
    {
        index: 43,
        hex: "#b5da9c",
        l: 83.10813243624811,
        a: -23.218226848335576,
        b: 26.630723867114003
    },
    {
        index: 44,
        hex: "#98cc6a",
        l: 76.61944952502857,
        a: -33.66854534435998,
        b: 43.21983526112674
    },
    {
        index: 45,
        hex: "#65ac43",
        l: 63.898513898671325,
        a: -41.48455032613191,
        b: 46.03046644906485
    },
    {
        index: 46,
        hex: "#637134",
        l: 45.235572390559234,
        a: -15.430455825378692,
        b: 31.767194084731354
    },
    {
        index: 47,
        hex: "#474c2a",
        l: 31.056796462631887,
        a: -8.260836949542272,
        b: 19.51652421007689
    },
    {
        index: 48,
        hex: "#1d1a0a",
        l: 9.172681805034475,
        a: -1.5265850746624814,
        b: 9.588017467997368
    },
    {
        index: 49,
        hex: "#fcf7bb",
        l: 96.32962485581093,
        a: -7.467309269998001,
        b: 29.522066687492334
    },
    {
        index: 50,
        hex: "#fff699",
        l: 95.77709068304968,
        a: -9.819603987044456,
        b: 45.76508583505776
    },
    {
        index: 51,
        hex: "#fff462",
        l: 94.67170747857813,
        a: -13.367913414827736,
        b: 69.4609551222167
    },
    {
        index: 52,
        hex: "#f9d308",
        l: 85.37541300751171,
        a: -2.389026116571491,
        b: 85.0828066163493
    },
    {
        index: 53,
        hex: "#c08028",
        l: 58.78689694631521,
        a: 17.470371584538736,
        b: 54.533702376356686
    },
    {
        index: 54,
        hex: "#5c331e",
        l: 25.97106573026158,
        a: 16.29836330502038,
        b: 21.085110446633436
    },
    {
        index: 55,
        hex: "#31120e",
        l: 9.93260715854963,
        a: 15.347860288012827,
        b: 9.275670175545942
    },
    {
        index: 56,
        hex: "#fedbb5",
        l: 89.42580228413208,
        a: 6.680240163997597,
        b: 23.18848040740289
    },
    {
        index: 57,
        hex: "#fdcd9c",
        l: 85.4764612275417,
        a: 10.537188132638653,
        b: 30.803671657458764
    },
    {
        index: 58,
        hex: "#f9a456",
        l: 74.4210728758726,
        a: 24.426256457673546,
        b: 52.378644715212744
    },
    {
        index: 59,
        hex: "#ec7322",
        l: 61.834846316647074,
        a: 42.33159201162906,
        b: 61.71529444386431
    },
    {
        index: 60,
        hex: "#b84926",
        l: 45.764551202897714,
        a: 43.074876270826735,
        b: 42.52839050870275
    },
    {
        index: 61,
        hex: "#6b2a1e",
        l: 26.376982704119634,
        a: 28.17102446995115,
        b: 22.17362016824009
    },
    {
        index: 62,
        hex: "#301110",
        l: 9.535061998782165,
        a: 15.709939228438358,
        b: 7.623643092094856
    },
    {
        index: 63,
        hex: "#f9bdc8",
        l: 82.21992986727871,
        a: 23.148763466700807,
        b: 2.8702983265733284
    },
    {
        index: 64,
        hex: "#f69ea6",
        l: 74.0614812755305,
        a: 33.73618661679417,
        b: 9.575482182732987
    },
    {
        index: 65,
        hex: "#f15d58",
        l: 59.19034702424463,
        a: 56.59483439506746,
        b: 33.01399717853242
    },
    {
        index: 66,
        hex: "#e51d25",
        l: 49.09376881400715,
        a: 71.15146069824773,
        b: 49.14500955038355
    },
    {
        index: 67,
        hex: "#aa2024",
        l: 37.310334375486214,
        a: 54.187489788011355,
        b: 34.138684615132654
    },
    {
        index: 68,
        hex: "#731f1c",
        l: 25.888051855911137,
        a: 36.44859736301231,
        b: 23.254391462990004
    },
    {
        index: 69,
        hex: "#2f1010",
        l: 9.09373980208984,
        a: 15.87598115983184,
        b: 6.966228377649319
    },
    {
        index: 70,
        hex: "#f9c7d5",
        l: 84.88953413354115,
        a: 19.868431773969352,
        b: -0.19913545044443204
    },
    {
        index: 71,
        hex: "#f59fb4",
        l: 74.5110288497666,
        a: 34.67639103404807,
        b: 2.5007311426792356
    },
    {
        index: 72,
        hex: "#f15e76",
        l: 59.90155170880733,
        a: 58.299710676981306,
        b: 16.70065537769123
    },
    {
        index: 73,
        hex: "#e11b4a",
        l: 48.61818178076541,
        a: 71.75290651648353,
        b: 27.321378839306632
    },
    {
        index: 74,
        hex: "#9e1f39",
        l: 35.09203782682641,
        a: 51.93865286180771,
        b: 17.871210113348567
    },
    {
        index: 75,
        hex: "#711d30",
        l: 25.571908507963656,
        a: 37.93316039655525,
        b: 9.480063723261134
    },
    {
        index: 76,
        hex: "#34111c",
        l: 10.66612184222273,
        a: 18.978709092799363,
        b: 1.017344834023881
    },
    {
        index: 77,
        hex: "#f9cde0",
        l: 86.595180702753,
        a: 18.484424330623305,
        b: -3.549394948275175
    },
    {
        index: 78,
        hex: "#f49fc5",
        l: 74.86312798011839,
        a: 36.75225102477131,
        b: -6.344846878567378
    },
    {
        index: 79,
        hex: "#ee4f9c",
        l: 58.19750214567233,
        a: 67.13861441469837,
        b: -8.000868394029027
    },
    {
        index: 80,
        hex: "#dd0b85",
        l: 48.64199995101252,
        a: 76.90466185129257,
        b: -8.905550188119605
    },
    {
        index: 81,
        hex: "#8d2162",
        l: 33.2050102704496,
        a: 50.27811600567067,
        b: -11.85779885959236
    },
    {
        index: 82,
        hex: "#641c47",
        l: 23.654128365435383,
        a: 36.72745583361167,
        b: -9.285688829815552
    },
    {
        index: 83,
        hex: "#391126",
        l: 12.028664164068328,
        a: 22.4894161025873,
        b: -4.339580626907385
    },
    {
        index: 84,
        hex: "#dcc6e0",
        l: 82.3710163863127,
        a: 12.315266584837559,
        b: -10.082534889262584
    },
    {
        index: 85,
        hex: "#c195c4",
        l: 67.14016444940073,
        a: 24.5900348289968,
        b: -17.919452160872584
    },
    {
        index: 86,
        hex: "#a65ba1",
        l: 49.692456680505984,
        a: 41.00111041570731,
        b: -24.8525015926478
    },
    {
        index: 87,
        hex: "#88328a",
        l: 36.650407390468,
        a: 48.451889696413424,
        b: -31.712077512607152
    },
    {
        index: 88,
        hex: "#6e2a70",
        l: 29.76489204123709,
        a: 40.2113929476747,
        b: -26.58610768840124
    },
    {
        index: 89,
        hex: "#4e2158",
        l: 21.623637073079983,
        a: 30.603003105233867,
        b: -24.15981307285502
    },
    {
        index: 90,
        hex: "#2e163a",
        l: 12.35243810841661,
        a: 19.794876911650384,
        b: -18.53164922925641
    }
]

export default paletteData;
