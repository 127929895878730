import React, {Component} from "react";

import Database from "utilities/database";
import ColourUtilities from "utilities/colour";
import paletteData from "resources/palette";

import {Colour, Props, State} from "./colour-matcher.interface";

import styles from "./colour-matcher.module.scss";

export class ColourMatcher extends Component<Props, State> {
    database: Database;

    state = {
        count: 0,
        activeColour: {
            index: 0,
            hex: '',
            l: 0,
            a: 0,
            b: 0
        }
    }

    componentDidMount() {
        this.database = new Database('colours', 'colour-match');
        this.database.open()
            .then(this.database.count.bind(this))
            .then((count) => {
                this.setState({
                    count
                })
            })

        this.newColour();
    }

    newColour = () => {
        function rand(min: number, max: number) {
            return min + Math.random() * (max - min);
        }

        const h = rand(1, 360);
        const s = rand(0, 100);
        const l = rand(0, 100);

        const randomHexColor = ColourUtilities.hslToHex(h, s, l);
        const rgbColour = ColourUtilities.hexToRGB(randomHexColor);

        this.setState({
            activeColour: {
                ...ColourUtilities.rgbToLab(rgbColour.r, rgbColour.g, rgbColour.b),
                index: 0,
                hex: randomHexColor,
            }
        });
    }

    setColour(colour: Colour){
        this.database.add({
            l: colour.l,
            a: colour.a,
            b: colour.b,
            hex: colour.hex,
            result: colour.index
        })
            .then(this.database.count.bind(this))
            .then((count) => {
                this.setState({
                    count
                })
            })
        this.newColour();
    }

    exportColours = () => {
        this.database.export('colour-match.json');
    }

    clearAll = () => {
        this.database.clearAll();
        this.setState({
            count: 0
        });
    }

    renderPalette(): React.JSX.Element[] {
        return paletteData.map((colour, index) => {
            return (
                <div
                    key={index}
                    className={styles.colour}
                    style={{background: colour.hex}}
                    onClick={this.setColour.bind(this, colour)}>
                    <div
                        className={styles.match}
                        style={{background: this.state.activeColour.hex}}/>
                </div>
            )
        });
    }

    render() {
        return (
            <>
                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <div onClick={this.newColour}>New</div>
                        </li>
                        <li>
                            <div onClick={this.exportColours}>Export</div>
                        </li>
                        <li>
                            <div onClick={this.clearAll}>Clear</div>
                        </li>
                        <li>
                            <div className={styles.disabled}>{this.state.count}</div>
                        </li>
                    </ul>
                </nav>
                <div className={styles.colourContainer}>{this.renderPalette()}</div>
            </>
        )
    }
}

export default ColourMatcher;
