import React, {useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import styles from './home.module.scss';

import Swipe from 'components/swipe';

import Main from "components/home/main";
import ColourMatcher from "components/home/colour-matcher";
import Placeholder from "components/home/placeholder";

export const Home = () => {
    const [direction, setDirection] = useState<'positive' | 'opposite'>('positive');
    const [targetIndex, setTargetIndex] = useState(2);

    const components = [
        Placeholder,
        Placeholder,
        Main, //starting page
        ColourMatcher,
        Placeholder,
    ]

    const onNavigate = (incremental: number) => {
        let index = targetIndex + incremental;
        index = index >= components.length ? components.length - 1 : (index < 0 ? 0 : index);

        setDirection(incremental > 0 ? 'positive' : 'opposite');
        setTargetIndex(index);
    };

    const renderChevron = (direction: 'left' | 'right') => {
        if (
            direction === 'left' && targetIndex === 0
            || direction === 'right' && targetIndex === components.length - 1) {
            return null;
        }

        return (
            <div
                className={`${styles.chevron} ${styles[direction]}`}
                onClick={onNavigate.bind(this, direction === 'left' ? -1 : 1)}></div>
        )
    }

    const onFooterClick = (index: number) => {
        const direction = index > targetIndex ? 'positive' : 'opposite';
        setDirection(direction);
        setTargetIndex(index);
    }

    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <div className={styles.main}>
                    <Swipe
                        onSwipeLeft={onNavigate.bind(this, 1)}
                        onSwipeRight={onNavigate.bind(this, -1)}>
                        <div className={styles.container}>
                            {
                                components.map((Component, index) => {
                                    const nodeRef = useRef(null);
                                    return (
                                        <CSSTransition
                                            key={index}
                                            nodeRef={nodeRef}
                                            in={index === targetIndex}
                                            timeout={{enter: 500, exit: 500}}
                                            unmountOnExit={true}
                                            classNames={`${direction}-slide`}>
                                            <div ref={nodeRef} className={styles.transitionContainer}>
                                                <Component/>
                                            </div>
                                        </CSSTransition>
                                    )
                                })
                            }
                            { renderChevron('left') }
                            { renderChevron('right') }
                        </div>
                    </Swipe>
                </div>
                <footer>
                    {
                        components.map((_item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${index === targetIndex ? styles.active : '' }`}
                                    onClick={() => onFooterClick(index)}>
                                    <div className={styles.dash}></div>
                                </div>
                            )
                        })
                    }
                </footer>
            </div>
        </div>
    )
}

export default Home;
