import styles from "pages/home/home.module.scss";
import React from "react";

export const Main = () => {
    return (
        <div className={styles.sliderMain}>
            <span className={styles.title}>
                <div>HOPWOOD</div>
                <span>.dev</span>
            </span>
            <div className={styles.titleBackground}>
                <div>MHUK</div>
            </div>
        </div>
    )
}

export default Main;
