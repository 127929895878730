import React, {Component, MouseEvent, TouchEvent} from 'react';

import {Props, State, swipePositions} from 'interfaces/swipe.interface';

export class Swipe extends Component<Props, State> {
    ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    position: swipePositions;

    componentDidMount() {
        this.reset();
        const node = this.ref.current!;

        node.addEventListener("touchstart", this.touchStart, true);
        node.addEventListener("touchmove", this.touchMove, true);
        node.addEventListener("touchend", this.touchEnd, true);

        node.addEventListener("mousedown", this.touchStart, true);
        node.addEventListener("mousemove", this.touchMove, true);
        node.addEventListener("mouseup", this.touchEnd, true);
    }

    componentWillUnmount() {
        const node = this.ref.current!;

        node.removeEventListener("touchstart", this.touchStart, true);
        node.removeEventListener("touchmove", this.touchMove, true);
        node.removeEventListener("touchend", this.touchEnd, true);

        node.removeEventListener("mousedown", this.touchStart, true);
        node.removeEventListener("mousemove", this.touchMove, true);
        node.removeEventListener("mouseup", this.touchEnd, true);
    }

    reset() {
        this.position = {
            start: {
                x: 0,
                y: 0
            },
            end: {
                x: 0,
                y: 0
            }
        }
    }

    getDirection (start: number, end: number) {
        if (start === 0 || end === 0) {
            return null;
        }

        const distance = end - start;

        if (Math.abs(distance) > 50) {
            return distance > 0 ? "positive" : "opposite";
        }
        return null;
    }

    runCallback(vertical: string | null, horizontal: string | null) {
        if (vertical) {
            if (vertical === 'positive' && this.props.onSwipeUp) {
                this.props.onSwipeUp();
            } else if (this.props.onSwipeDown) {
                this.props.onSwipeDown();
            }
        }

        if (horizontal) {
            if (horizontal === 'positive' && this.props.onSwipeRight) {
                this.props.onSwipeRight();
            } else if (this.props.onSwipeLeft) {
                this.props.onSwipeLeft();
            }
        }
    }

    getTouch (event: Event) {
        if ((event as unknown as TouchEvent).touches) {
            return (event as unknown as TouchEvent).touches[0];
        }
        return event as unknown as MouseEvent;
    }

    touchStart = (event: Event) => {
        const touch = this.getTouch(event);
        this.position.start.x = touch.clientX;
        this.position.start.y = touch.clientY;
    }

    touchMove = (event: Event) => {
        const touch = this.getTouch(event);
        this.position.end.x = touch.clientX;
        this.position.end.y = touch.clientY;
    }

    touchEnd = () => {
        const position = this.position;

        const vertical = this.getDirection(position.start.y, position.end.y);
        const horizontal = this.getDirection(position.start.x, position.end.x);

        this.runCallback(vertical, horizontal);
        this.reset();
    }

    render() {
        return (
            <div ref={this.ref}>
                {this.props.children}
            </div>
        );
    }
}

export default Swipe;
