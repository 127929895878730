class Database {
    db: IDBDatabase;
    table: string;
    name: string;
    matchCount: number;

    constructor(table: string, name: string) {
        this.setTable(table);
        this.setName(name);

        this.open()
            .then(this.countObjectStore.bind(this))
            .then(this.setCount.bind(this));
    }

    setTable(table: string) {
        this.table = table;
    }

    setName(name: string) {
        this.name = name;
    }

    setCount(count: number) {
        this.matchCount = count;
    }

    getCount() {
        return this.count || 0;
    }

    open() {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(this.name, 1);
            request.onerror = () => {
                reject(`Database failed to open: ${request.error}`);
            };
            request.onsuccess = () => {
                this.db = request.result;
                resolve('success');
            };
            request.onupgradeneeded = () => {
                const db = request.result;
                db.createObjectStore(this.table, {autoIncrement: true});
                resolve('upgraded');
            };
        });
    }

    add (record: any): Promise<string> {
        return new Promise((resolve, reject) => {
            // opening each time may not be efficient
            const transaction = this.db.transaction([this.table], "readwrite");

            const objectStore = transaction.objectStore(this.table);
            const request = objectStore.add(record);
            request.onsuccess = () => {
                resolve('added');
            };
            request.onerror = () => {
                reject(`Failed to add: ${request.error}`);
            };
        });
    }

    export (fileName: string) {
        const transaction = this.db.transaction([this.table], "readonly");
        const objectStore = transaction.objectStore(this.table);
        const request = objectStore.getAll();

        request.onsuccess = () => {
            const data = request.result;
            const json = JSON.stringify(data, null, 2);
            const blob = new Blob([json], {type: 'application/json'});
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        };
    }

    count = async () => {
        return this.countObjectStore();
    }

    countObjectStore (): Promise<number> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([this.table], "readonly");
            const objectStore = transaction.objectStore(this.table);

            const countRequest = objectStore.count();
            countRequest.onsuccess = () => {
                resolve(countRequest.result);
            };
            countRequest.onerror = () => {
                reject(0);
            };
        });
    }

    clearAll () {
        const transaction = this.db.transaction([this.table], "readwrite");
        const objectStore = transaction.objectStore(this.table);
        const request = objectStore.clear();
        request.onsuccess = () => {
            this.setCount(0);
        };
    }
}

export default Database;
