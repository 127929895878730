import React from 'react';

export const Error404 = () => {
    return (
        <div>
            <h1>404</h1>
        </div>
    )
}

export default Error404;
